require('./main.css')
import {World} from '../World/World.js'

async function main() {
    const container = document.querySelector('#scene-container')
    const world = new World(container)
    await world.init()

    world.start(console.log('started'))
    world.setCurrentPage('enter')

    
    window.addEventListener('message', function (e) {
        // Get the sent data
        const data = e.data
        let msg = {}

        if (typeof data !== 'string') {
            return
        }

        msg = JSON.parse(data)

        if (msg.type === 'gsap') {
            world.setCurrentPage(msg.gsapto, msg.position)
        }
    });

    window.addEventListener('hashchange', function(e) {
        let hashArr = window.location.hash.split(':')
        e.preventDefault()
        if (hashArr[1]) {
            world.setCurrentPage(hashArr[1])
        }
    })

    $(document).ready(function() {
        $('#pageContainer').on('click', '.gsap-trigger', function() {
            world.setCurrentPage($(this).data('gsapto'), $(this).data('position'))
        })
    })
}

main().catch(err => {
    console.error(err)
})
